<template>
  <b-card title="Test Sonuçları">
    <b-list-group>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Martindale / Cycle
          </div>
          <div v-if="dataItem.tests.asinma">
            {{ dataItem.tests.asinma | toNumber }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Kopuş
          </div>
          <div v-if="dataItem.tests.asinma_sonuc">
            <template v-if="dataItem.tests.asinma_sonuc === '1'">
              VAR
            </template>
            <template v-else-if="dataItem.tests.asinma_sonuc === '0'">
              YOK
            </template>
            <template v-else>
              {{ dataItem.tests.asinma_sonuc }}
            </template>
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Pilling / Cycle
          </div>
          <div v-if="dataItem.tests.pilling">
            {{ dataItem.tests.pilling | toNumber }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Pilling Değeri
          </div>
          <div v-if="dataItem.tests.pilling_sonuc">
            {{ dataItem.tests.pilling_sonuc }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Renk Haslığı Değeri / Kuru
          </div>
          <div v-if="dataItem.tests.rh_kuru">
            {{ dataItem.tests.rh_kuru }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Renk Haslığı Değeri / Yaş
          </div>
          <div v-if="dataItem.tests.rh_yas">
            {{ dataItem.tests.rh_yas }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="text-primary font-weight-bold">
            Hav Kaybı Başlangıç Değeri
          </div>
          <div v-if="dataItem.tests.hav_kaybi">
            {{ dataItem.tests.hav_kaybi }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'TestResult',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['products/dataItem']
    },
  },
}
</script>
