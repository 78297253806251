<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <product-group />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <product-qualities :is-disabled="!customAdd" />
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <primary-product-code :is-disabled="!customAdd" />
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <product-code :is-disabled="!customAdd" />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <product-width />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <product-weight />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <product-price />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <currencies />
        </b-col>
        <b-col cols="12">
          <product-content />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12">
        <test-result />
      </b-col>
      <b-col cols="12">
        <photo-gallery />
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'
import ProductGroup from '@/views/Admin/Products/elements/ProductGroup.vue'
import ProductQualities from '@/views/Admin/Products/elements/ProductQualities.vue'
import PrimaryProductCode from '@/views/Admin/Products/elements/PrimaryProductCode.vue'
import ProductCode from '@/views/Admin/Products/elements/ProductCode.vue'
import ProductWidth from '@/views/Admin/Products/elements/Width.vue'
import ProductWeight from '@/views/Admin/Products/elements/Weight.vue'
import ProductPrice from '@/views/Admin/Products/elements/Price.vue'
import Currencies from '@/views/Admin/Products/elements/Currencies.vue'
import ProductContent from '@/views/Admin/Products/elements/Content.vue'
import PhotoGallery from '@/views/Admin/Products/elements/PhotoGallery.vue'
import TestResult from '@/views/Admin/Products/elements/TestResult.vue'

export default {
  name: 'ProductCategoryForm',
  components: {
    ProductGroup,
    ProductQualities,
    PrimaryProductCode,
    ProductCode,
    ProductWidth,
    ProductWeight,
    ProductPrice,
    Currencies,
    ProductContent,
    PhotoGallery,
    TestResult,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  props: {
    customAdd: {
      type: Boolean,
      default: false,
    },
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
  },
}
</script>
